import isEqual from "lodash/isEqual";
import omit from "lodash/omit";

import type { ContactFlowMetadata } from "~~/model/analytics.model";

type GrowthContactFormHistoryEntry = {
  email: string;
  created_at: string;
} & ContactFlowMetadata;

export function useGrowthContactFormHistory() {
  const { hasCompletePII } = useGrowthContact();
  const historyCookie = useCookie<GrowthContactFormHistoryEntry[]>(
    "growth_contact_form_history",
    {
      default: () => [],
    },
  );

  function exists(entry: Omit<GrowthContactFormHistoryEntry, "created_at">) {
    if (!hasCompletePII.value) return false;

    return historyCookie.value.some((historyEntry) =>
      isEqual(omit(historyEntry, "created_at"), entry),
    );
  }

  function record(entry: Omit<GrowthContactFormHistoryEntry, "created_at">) {
    historyCookie.value.push({
      ...entry,
      created_at: new Date().toISOString(),
    });
  }

  return {
    exists,
    record,
  };
}
